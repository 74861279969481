import React, { useState, useEffect } from "react";
import axios from "axios";
import { AOO_API_HOST } from "../../env";
import { IMachineMetrics } from "../../global.typing";
import {
  Title,
  Content,
  Average,
  InnerContainer,
  TableKPI,
} from "./KPI_style";

interface IKpiValues {
  AvailabilityMonth: number;
  AvailabilityTrimester: number;
  AvailabilityYear: number;
  DowntimeCostMonth: number;
  DowntimeCostTrimester: number;
  DowntimeCostYear: number;
}

const KpiContent = () => {
  const [kpiValues, setKpiValues] = useState<IKpiValues>({
    AvailabilityMonth: 0,
    AvailabilityTrimester: 0,
    AvailabilityYear: 0,
    DowntimeCostMonth: 0,
    DowntimeCostTrimester: 0,
    DowntimeCostYear: 0,
  });

  useEffect(() => {
    const fetchKpiValues = async () => {
      try {
        const response = await axios.get(
          AOO_API_HOST + "/aoo-api/Productions/KpiValues"
        );
        setKpiValues(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchKpiValues();
  }, []);

  const formatNumber = (value: number): string => {
    if (value >= 1_000_000) {
      return (value / 1_000_000).toFixed(2) + "M"; // Millions
    } else if (value >= 1_000) {
      return (value / 1_000).toFixed(0) + "K"; // Thousands
    } else {
      return value.toFixed(0); // Below thousand
    }
  };

  return (
    <Content>
      <InnerContainer>
          <TableKPI>
            <thead>
              <tr>
                <th></th>
                <th><Title>Last Month</Title></th>
                <th><Title>Last Trimester</Title></th>
                <th><Title>Last Year</Title></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Title>Avg. Availability</Title></td>
                <td><Average>{kpiValues.AvailabilityMonth}%</Average></td>
                <td><Average>{kpiValues.AvailabilityTrimester}%</Average></td>
                <td><Average>{kpiValues.AvailabilityYear}%</Average></td>
              </tr>
              <tr>
                <td><Title>Downtime Cost</Title></td>
                <td><Average>${formatNumber(kpiValues.DowntimeCostMonth)}</Average></td>
                <td><Average>${formatNumber(kpiValues.DowntimeCostTrimester)}</Average></td>
                <td><Average>${formatNumber(kpiValues.DowntimeCostYear)}</Average></td>
              </tr>
            </tbody>
          </TableKPI>
      </InnerContainer>
    </Content>
  );
};

export default KpiContent;
